<template>
  <v-container style="max-width: 100%; padding: 0;">
    <div class="d-flex justify-center align-center">
      <EstoreCard />
    </div>

    <h2
      class="d-flex justify-center align-center"
      style="text-align: center;"
    >
      フォトコンテスト結果発表
    </h2>

    <v-divider
      :thickness="4"
      class="my-4"
    />

    <h3
      class="d-flex justify-center align-center"
      style="text-align: center; "
    >
      👑最優秀賞(1204万＋ダイス)👑
    </h3>

    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705500099088514/a08a490e55eca6ef.png"
      author-name="いっしーのおへや"
      author-twitter="https://twitter.com/isshinooheya"
      tweet-url="https://twitter.com/isshinooheya/status/1690328992673615872/photo/1"
    />

    <v-divider
      :thickness="4"
      class="my-4"
    />

    <h3
      class="d-flex justify-center align-center"
      style="text-align: center;"
    >
      優秀賞(500万)
    </h3>

    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705596878475445/3.png"
      author-name="わさび⑅ ⋆"
      author-twitter="https://twitter.com/wasabi_3381g"
      tweet-url="https://twitter.com/wasabi_3381g/status/1689996891369472001?s=46&t=RjOm-Ksm8ldqVsnfyiLvew"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705541261991956/2.png"
      author-name="もえぴ🐕"
      author-twitter="https://twitter.com/pipimoepi0w0"
      tweet-url="https://twitter.com/pipimoepi0w0/status/1690524392214953984?s=20"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705521104175174/1.png"
      author-name="ほーりー at Unicorn"
      author-twitter="https://twitter.com/holly_uni_ff14"
      tweet-url="https://twitter.com/holly_uni_ff14/status/1690105815443582976"
    />

    <v-divider
      :thickness="4"
      class="my-4"
    />

    <h3
      class="d-flex justify-center align-center"
      style="text-align: center;"
    >
      入賞(300万)
    </h3>

    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705758854086828/5.png"
      author-name="TanTan"
      author-twitter="https://twitter.com/TanTan_FF14"
      tweet-url="https://twitter.com/tantan_ff14/status/1690622551276601344?s=46&t=RjOm-Ksm8ldqVsnfyiLvew"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705711320043581/3.png"
      author-name="とろまる@MeteorDC"
      author-twitter="https://twitter.com/toromaru_rarara"
      tweet-url="https://twitter.com/toromaru_rarara/status/1690564406550409217?s=46&t=RjOm-Ksm8ldqVsnfyiLvew"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141726375103967292/6.png"
      author-name="みには"
      author-twitter="https://twitter.com/miniminiha"
      tweet-url="https://twitter.com/miniminiha/status/1690027198894440448"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705689035722784/2.png"
      author-name="せれね*⑅୨୧"
      author-twitter="https://twitter.com/selene88384357"
      tweet-url="https://twitter.com/selene88384357/status/1690563058169675776"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705734455824524/4.png"
      author-name="あるてあ❖Typhon"
      author-twitter="https://twitter.com/FFXIV_Altea"
      tweet-url="https://twitter.com/FFXIV_Altea/status/1690344962511224832"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705802932047962/8.png"
      author-name="砕蔵"
      author-twitter="https://twitter.com/saizoh2"
      tweet-url="https://twitter.com/saizoh2/status/1690311244182675456"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705781398487130/7.png"
      author-name="🌱Memory＊ixion🐟Skeb✏️"
      author-twitter="https://twitter.com/awoi0w0"
      tweet-url="https://twitter.com/awoi0w0/status/1690540857185984512"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705650162892902/922b281a26dc19d6.png"
      author-name="ハナ"
      author-twitter="https://twitter.com/hana_hikasen"
      tweet-url="https://twitter.com/hana_hikasen/status/1690687090252812289"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705824197156884/9.png"
      author-name="あいはらせと（漫画家＆イラストレーター）"
      author-twitter="https://twitter.com/setoaihara"
      tweet-url="https://twitter.com/setoaihara/status/1690564555964071937/photo/3"
    />
    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705847538470952/10.png"
      author-name="雪詩(Yukiuta)"
      author-twitter="https://twitter.com/yukiuta_0417"
      tweet-url="https://twitter.com/yukiuta_0417/status/1690910537847394304"
    />

    <v-divider
      :thickness="4"
      class="my-4"
    />

    <h3
      class="d-flex justify-center align-center"
      style="text-align: center;"
    >
      💣シャルロッテ賞(500万)💣
    </h3>

    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705911753261076/eada11f53ff7fc98.png"
      author-name="ほーりー at Unicorn"
      author-twitter="https://twitter.com/holly_uni_ff14"
      tweet-url="https://twitter.com/holly_uni_ff14/status/1690242069531611136"
    />

    <v-divider
      :thickness="4"
      class="my-4"
    />

    <h3
      class="d-flex justify-center align-center"
      style="text-align: center;"
    >
      よくわからないで賞(300万)
    </h3>

    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705932208873562/b2ebbd0bcac3f424.png"
      author-name="あるてあ❖Typhon"
      author-twitter="https://twitter.com/FFXIV_Altea"
      tweet-url="https://twitter.com/FFXIV_Altea/status/1690343365412634624"
    />

    <v-divider
      :thickness="4"
      class="my-4"
    />

    <h3
      class="d-flex justify-center align-center"
      style="text-align: center;"
    >
      かんばったで賞(300万)
    </h3>

    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141705886008614973/2b5ba9c47f315c1c.png"
      author-name="リエまる🍷໒꒱"
      author-twitter="https://twitter.com/Rie_Malu"
      tweet-url="https://twitter.com/Rie_Malu/status/1689664593499725824?s=20"
    />

    <v-divider
      :thickness="4"
      class="my-4"
    />

    <h3
      class="d-flex justify-center align-center"
      style="text-align: center;"
    >
      おつかれさまで賞(300万)
    </h3>

    <photo
      src="https://cdn.discordapp.com/attachments/974065465531969556/1141708091931512902/2295b3e175217f2c.png"
      author-name="ゆまる@どこでもゆまる&演奏ララ"
      author-twitter="https://twitter.com/yumaru_ff_yacy"
      tweet-url="https://twitter.com/yumaru_ff_yacy/status/1690559298198552576"
    />

    <v-divider
      :thickness="4"
      class="my-4"
    />

    <h2
      class="d-flex justify-center align-center"
      style="text-align: center;"
    >
      フォトコンSS一覧
    </h2>

    <div class="d-flex justify-center align-center">
      <v-chip
        size="x-large"
        color="secondary"
        label
        text-color="white"
      >
        応募SS総数: {{ totalImagesCount }}
      </v-chip>
    </div>

    <div class="d-flex justify-center align-center">
      <div
        v-masonry
        transition-duration="0.3s"
        item-selector=".item"
        class="masonryWrap"
        :origin-top="true"
        :horizontal-order="false"
      >
        <div
          v-for="(item, index) in events"
          :key="index"
        >
          <div
            v-for="(i, ind) in item.img_urls"
            :key="ind"
            v-masonry-tile
            class="item col-6 col-md-4"
          >
            <!-- <div class="image-number-label">
              {{ item.img_numbers[ind] }}
            </div> -->
            <img
              :src="i"
              width="100%"
              class="item-image"
              @click="openDialog(i,item)"
            >
          </div>
        </div>
      </div>
    </div>
    <!-- ここで無限スクロールのトリガーを配置します -->
    <div v-intersect="onIntersect" />

    <v-dialog
      :key="dialogKey"
      v-model="dialog"
      width="80%"
    >
      <v-card
        color="grey-lighten-4"
        flat
      >
        <v-card-title>
          <span class="headline" />
        </v-card-title>
        <v-card-text>
          <a
            :href="tweetURL"
            target="_blank"
          >
            <v-img
              :lazy-src="imgSelectedEvent"
              :src="modifiedImageSrc"
              contain
              width="100%"
              max-height="80vh"
            >
              <template v-slot:placeholder>
                <div
                  class="fill-height ma-0 d-flex align-center justify-center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </div>
              </template>
            </v-img>
          </a>
          <!-- <p
            class="centered-h3"
            v-html="selectedTweetText"
          /> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            variant="text"
            color="secondary"
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import Photo from '../components/Photo.vue'

  export default {
    name: 'EventPhoto',
    metaInfo: {
      title: 'EventPhoto',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },
    components: { Photo },
    data: function () {
      return {
        events: [],
        dialog: false,
        imgSelectedEvent: 'https://pbs.twimg.com/media/F1pLwG-aEAIg2vd?format=jpg&name=900x900',
        tweetURL: '',
        dialogKey: 0, // キーの初期値
        selectedTweetText: '', // 選択されたイベントのtweet_textを保持
        allEvents: [], // 全てのデータを一時保存するための配列
        perPage: 20, // 一度に表示するデータの数
        currentPage: 1, // 現在のページ番号
        isLoading: false,
        totalImagesCount: 0, // 応募総数
      }
    },
    computed: {
      modifiedImageSrc () {
        let url = ''
        if (this.imgSelectedEvent !== '') {
          url = new URL(this.imgSelectedEvent)
          url.searchParams.delete('name')
        }
        return url.toString()
      },
    },
    watch: {
      dialog (val) {
        if (val) {
          this.dialogKey++ // ダイアログが開くたびにキーをインクリメント
        }
      },
    },
    mounted: async function () {
      await this.fetchEvents()
    },
    methods: {
      loadMoreEvents () {
        const existingImageUrlsSet = new Set()

        const baseOfUrl = (url) => new URL(url).origin + new URL(url).pathname

        this.events.forEach(event => {
          event.img_urls.forEach(url => existingImageUrlsSet.add(baseOfUrl(url)))
        })

        const start = (this.currentPage - 1) * this.perPage
        const end = this.currentPage * this.perPage

        if (start < this.allEvents.length) {
          const newEvents = JSON.parse(JSON.stringify(this.allEvents.slice(start, end)))

          for (let i = 0; i < newEvents.length; i++) {
            const event = newEvents[i]
            const originalLength = event.img_urls.length

            event.img_urls = event.img_urls.filter(url => {
              const base = baseOfUrl(url)
              if (existingImageUrlsSet.has(base)) {
                // console.log(`重複URL: ${url} (ベース: ${base}) が既存のSetに存在します。`)
                return false
              } else {
                existingImageUrlsSet.add(base)
                return true
              }
            })

            const removedCount = originalLength - event.img_urls.length

            if (removedCount > 0) {
              // console.log(`${removedCount} 枚の重複画像がイベントID: ${event.text || '未知'} から排除されました。`)
            }
          }

          this.events = this.events.concat(newEvents)
          this.currentPage++
        }
      },

      onIntersect (entries) {
        if (entries[0].isIntersecting && !this.isLoading && this.events.length < this.allEvents.length) {
          this.isLoading = true
          this.loadMoreEvents()
          this.isLoading = false
        }
      },
      // onIntersect (entries) {
      //   if (entries[0].isIntersecting && !this.isLoading) {
      //     this.loadMore()
      //   }
      // },

      formatWithLinks (text) {
        const segments = text.split('<br>')
        const formattedSegments = segments.map(segment => {
          // URLを検出し、aタグで囲む正規表現を使用します
          const urlRegex = /(https?:\/\/[^\s<]+)/g
          return segment.replace(urlRegex, url => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`)
            .replace(/\n/g, '<br>') // 改行文字を<br>に置換
        })
        return formattedSegments.join('<br>')
      },

      openDialog (imageUrl, item) {
        this.imgSelectedEvent = imageUrl
        this.tweetURL = item.tweet_url

        // 正規表現でユーザー名を抽出
        const usernameRegex = /^(.*?)\n@/
        const usernameMatch = item.tweet_text.match(usernameRegex)
        const username = usernameMatch ? usernameMatch[1].trim() : ''

        // 正規表現でツイート内容を抽出
        const tweetContentRegex = /·\nフォローする\n([\s\S]+?)\n(午前|午後)/
        const tweetContentMatch = item.tweet_text.match(tweetContentRegex)
        const tweetContent = tweetContentMatch ? tweetContentMatch[1].trim().replace(/\n\n/g, ' ') : ''

        this.selectedTweetText = `${username}:\n ${tweetContent}`

        this.selectedTweetText = this.formatWithLinks(this.selectedTweetText) // tweet_textにformatWithLinksを適用
        this.dialog = true
      },
      async fetchEvents () {
        let imageNumber = 1 // イメージナンバリングの開始
        if (this.allEvents.length === 0) {
          await axios.get(`../../../photo/all_users_photo.json?timestamp=${new Date().getTime()}`)
            .then(response => {
              this.allEvents = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                .map(event => {
                  // 同イベント内での画像URLの重複を取り除く
                  event.img_urls = [...new Set(event.img_urls)]
                  event.img_numbers = event.img_urls.map(() => imageNumber++)

                  return event
                })

              let allImageUrls = []
              this.allEvents.forEach(event => {
                allImageUrls = allImageUrls.concat(event.img_urls)
              })

              // Check and log duplicate URLs
              const urlCount = {}
              allImageUrls.forEach(url => {
                urlCount[url] = (urlCount[url] || 0) + 1
                if (urlCount[url] > 1) {
                  console.log(`Duplicate URL found: ${url}`)
                }
              })

              const uniqueImageUrls = [...new Set(allImageUrls)] // Remove duplicates
              this.totalImagesCount = uniqueImageUrls.length // Update the total images count
            })
        }
        this.loadMoreEvents()
      },

    },
  }
</script>

<style scoped>
.masonryWrap {
  align: center;
  width: 100%;
}

.item {
  padding: 5px;  /* 画像の周りのスペースを調整. これは例として5pxを追加していますが、必要に応じて値を変更してください。 */
}

.item-image {
  width: 100%;
  height: auto;
  margin: 0;  /* 画像間のマージンを0に設定 */
  display: block; /* 画像の下部にできる余白を削除するために追加 */
}

.centered-h3 {
  color: black;
  /* display: flex; */
  /* justify-content: center; */
  text-align: center; /* テキストを中央揃え */
  width: 100%; /* 必要に応じて調整 */
  margin-top: 20px; /* こちらで上部のマージンを20pxに設定。適切な値に調整してください */
  word-wrap: break-word; /* 長い単語やURLが含まれている場合の折り返しを強制 */
  word-break: break-all;
}

</style>
